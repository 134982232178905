import React from "react"
import { QueryClient, QueryClientProvider } from "react-query"

import "./src/styles/font-face.css"

const onRouteUpdate = ({ location }) => {
  // const studentProfile = window.localStorage.getItem(STUDENT_PROFILE_KEY)
  // const teacherProfile = window.localStorage.getItem(TEACHER_PROFILE_KEY)
  // const { pathname } = location
  // const isWelcomeSignup = pathname.includes("/welcome")
  //
  // if (!studentProfile && !teacherProfile && !isWelcomeSignup) navigate("/welcome", { replace: true })
}

const client = new QueryClient()

const wrapRootElement = ({ element }) => {
  return <QueryClientProvider client={client}>{element}</QueryClientProvider>
}

export { onRouteUpdate, wrapRootElement }
