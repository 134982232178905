exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-exercise-js": () => import("./../../../src/pages/exercise.js" /* webpackChunkName: "component---src-pages-exercise-js" */),
  "component---src-pages-games-test-index-js": () => import("./../../../src/pages/gamesTest/index.js" /* webpackChunkName: "component---src-pages-games-test-index-js" */),
  "component---src-pages-games-test-selection-js": () => import("./../../../src/pages/gamesTest/selection.js" /* webpackChunkName: "component---src-pages-games-test-selection-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-student-index-js": () => import("./../../../src/pages/student/index.js" /* webpackChunkName: "component---src-pages-student-index-js" */),
  "component---src-pages-student-profile-edit-js": () => import("./../../../src/pages/student/profile/edit.js" /* webpackChunkName: "component---src-pages-student-profile-edit-js" */),
  "component---src-pages-student-profile-index-js": () => import("./../../../src/pages/student/profile/index.js" /* webpackChunkName: "component---src-pages-student-profile-index-js" */),
  "component---src-pages-student-profile-mission-control-index-js": () => import("./../../../src/pages/student/profile/mission_control/index.js" /* webpackChunkName: "component---src-pages-student-profile-mission-control-index-js" */),
  "component---src-pages-student-profile-space-home-planet-js": () => import("./../../../src/pages/student/profile/space/home_planet.js" /* webpackChunkName: "component---src-pages-student-profile-space-home-planet-js" */),
  "component---src-pages-student-profile-space-ice-skater-js": () => import("./../../../src/pages/student/profile/space/iceSkater.js" /* webpackChunkName: "component---src-pages-student-profile-space-ice-skater-js" */),
  "component---src-pages-student-profile-space-index-js": () => import("./../../../src/pages/student/profile/space/index.js" /* webpackChunkName: "component---src-pages-student-profile-space-index-js" */),
  "component---src-pages-student-profile-space-letter-monster-js": () => import("./../../../src/pages/student/profile/space/letter_monster.js" /* webpackChunkName: "component---src-pages-student-profile-space-letter-monster-js" */),
  "component---src-pages-student-profile-space-memory-js": () => import("./../../../src/pages/student/profile/space/memory.js" /* webpackChunkName: "component---src-pages-student-profile-space-memory-js" */),
  "component---src-pages-student-profile-space-racer-js": () => import("./../../../src/pages/student/profile/space/racer.js" /* webpackChunkName: "component---src-pages-student-profile-space-racer-js" */),
  "component---src-pages-student-profile-space-word-monster-js": () => import("./../../../src/pages/student/profile/space/word_monster.js" /* webpackChunkName: "component---src-pages-student-profile-space-word-monster-js" */),
  "component---src-pages-teacher-dashboard-diagrams-js": () => import("./../../../src/pages/teacher/dashboard/diagrams.js" /* webpackChunkName: "component---src-pages-teacher-dashboard-diagrams-js" */),
  "component---src-pages-teacher-dashboard-index-js": () => import("./../../../src/pages/teacher/dashboard/index.js" /* webpackChunkName: "component---src-pages-teacher-dashboard-index-js" */),
  "component---src-pages-teacher-index-js": () => import("./../../../src/pages/teacher/index.js" /* webpackChunkName: "component---src-pages-teacher-index-js" */),
  "component---src-pages-teacher-profile-index-js": () => import("./../../../src/pages/teacher/profile/index.js" /* webpackChunkName: "component---src-pages-teacher-profile-index-js" */)
}

